.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.column {
  float: left;
  width: 20%;
  padding: 5px;
  margin: 4em;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

.bottom {
  max-width: 100vw;
  margin-top: 10px;
}

.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -22em;
  
}


.textfield {
  margin-top: 10px;
}

.points-container {
  display: flex;
  justify-content: space-between;
}

.your-points {
  float: left;
  margin-left: 2rem; 
}

.opponents-points {
  float: right;
  margin-right: 2rem; 
}
.cards-handled {
  font-size: ;
}


.container {
  margin-top: 20rem;
  display: flex;
  justify-content: center;
  align-items: center; 
}

.row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}


.bid-button {
  margin-top: .5rem;
  display: inline-block;
  padding: .1rem 1.75rem;
  border-radius: 10rem;
  color: #ffffff;
  text-transform: uppercase;
  font-size: rem;
  letter-spacing: .15rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1AAEB5;
    border-radius: 10rem;
    z-index: -2;
  }
  
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color:#158C90;
    transition: all .3s;
    border-radius: 10rem;
    z-index: -1;
  }
  
  &:hover {
    color: rgb(255, 255, 255);
    &:before {
      width: 100%;
    }
  }
}  

.copy-button {
  margin-top: .5rem;
  display: inline-block;
  padding: .1rem .5rem;
  border-radius: 10rem;
  color: #ffffff;
  text-transform: uppercase;
  font-size: rem;
  letter-spacing: .15rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1AAEB5;
    border-radius: 10rem;
    z-index: -2;
  }
  
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color:#158C90;
    transition: all .3s;
    border-radius: 10rem;
    z-index: -1;
  }
  
  &:hover {
    color: rgb(255, 255, 255);
    &:before {
      width: 100%;
    }
  }
}  

.start-button {
  margin-top: 3rem;
  margin-left: 2rem;
  display: inline-block;
  padding: .75rem 2rem;
  border-radius: 10rem;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 1.25rem;
  letter-spacing: .15rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1AAEB5;
    border-radius: 10rem;
    z-index: -2;
  }
  
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color:#158C90;
    transition: all .3s;
    border-radius: 10rem;
    z-index: -1;
  }
  
  &:hover {
    color: rgb(255, 255, 255);
    &:before {
      width: 100%;
    }
  }
}  
body {
  font-family: 'Roboto', sans-serif;
}

.bid-input {
  margin-top: 1rem;
  font-size: 16px;
  padding: 8px;
  border: 1px solid #000000;
  background-color:rgb(238, 238, 238);
}

.main-deck {
  display: flex;
  justify-content: center;
  margin-top: -2rem;
  font-size: 16px;
  padding: 8px;
  max-width: 70vw;
  flex-wrap: wrap;
  margin-left: 15vw;
  min-height: 325px;
}
.side-board {
  display: flex;
  justify-content: center;
  margin-top: 0rem;
  font-size: 16px;
  padding: 8px;
  max-width: 70vw;
  flex-wrap: wrap;
  margin-left: 15vw;
  min-height: 325px;
}

.swap-cards-displayed {
  display: flex;
  justify-content: center;
  margin-top: 6rem;
  font-size: 16px;
  padding: 8px;
  border: 1px solid #6CB4EE;
  max-width: 100vw;
}

.buttons-container {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.swap-cards-button {
  width: 10vw;
  height: 2.5rem;
  margin-top: .5rem;
  margin-right: 2vw;
  display: inline-block;
  padding: .1rem .5rem;
  border-radius: 10rem;
  color: #ffffff;
  text-transform: uppercase;
  font-size: rem;
  letter-spacing: .15rem;
  transition: all .3s;
  position: absolute;
  right: 0;
  margin-left: 1rem;
  margin-bottom: 4rem;
  overflow: hidden;
  z-index: 1;
  
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1AAEB5;
    border-radius: 10rem;
    z-index: -2;
  }
  
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color:#158C90;
    transition: all .3s;
    border-radius: 10rem;
    z-index: -1;
  }
  
  &:hover {
    color: rgb(255, 255, 255);
    &:before {
      width: 100%;
    }
  }
}  

.deck-header {
  font-size: 1.2rem;
  font-weight: bold;
  position: absolute;
  top: -1.5rem; 
  left: 0;
  padding: 0 5px;
}
.logo {
  height: 42vh;
  width: auto;
}


html, body {
  height: 100%;
  margin: 0;
  background-color:rgb(238, 238, 238);
}

.main-deck-container,
.side-board-container {
  margin-top: 3rem;
  position: relative;
}

.home-page {
  text-align: center;
}

.results {
  margin-top: 1.5rem;
  margin-bottom: .7rem;
}

.info-container {
  width: 80%;
  margin: auto;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  color: #333;
  margin-top: 2rem;
}

.info-spacing {
  margin-top: 5rem;
}

.info-header{
  color:#158C90;
}

.info-button-container{
  display: flex;
  justify-content: center;
}

.git-hub{
  position: fixed;
  bottom: 0;
  right: 0;
}

.git-hub-button {
  border: none;
  width: 80px;
  height: 80px;
}

.git-hub-button img {
  width: 100%;
  height: auto;
}